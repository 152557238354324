function Input({
  className,
  placeholderText,
  defaultText,
  onChangeCallback,
  onEnterCallback,
  type,
}) {
  return (
    <div className={className}>
      <input
        className='p-1 border-2 border-gray-700 focus:border-black focus:outline-none focus:ring-1 focus:ring-black text-center'
        placeholder={placeholderText}
        defaultValue={defaultText}
        onChange={(event) => {
          onChangeCallback(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.code === 'Enter') {
            onEnterCallback();
          }
        }}
        type={type || ''}
      ></input>
    </div>
  );
}

export default Input;
