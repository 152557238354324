import 'firebase/database';
import { ref, set, get } from 'firebase/database';
import { GetDatabase } from '../DatabaseConfiguration';

const database = GetDatabase();

export function GetElections() {
  return new Promise((resolve, reject) => {
    get(ref(database, 'elections')).then((electionsSnapshot) => {
      resolve(Object.values(electionsSnapshot.val()));
    });
  });
}

export function GetCurrentPresident() {
  return new Promise((resolve, reject) => {
    get(ref(database, 'currentPresident'))
      .then((currentPresidentSnapshot) => {
        resolve(currentPresidentSnapshot.val());
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdatePresident(citizen) {
  return new Promise((resolve, reject) => {
    set(ref(database, `currentPresident`), citizen)
      .then(() => {
        resolve('Success');
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateElectionWinner(election, citizen) {
  return new Promise((resolve, reject) => {
    get(ref(database, `elections/${election.electionID}`)).then(
      (electionSnapshot) => {
        const election = electionSnapshot.val();
        election.winner = citizen;
        set(ref(database, `elections/${election.electionID}`), election)
          .then(() => {
            resolve('Success');
          })
          .catch((error) => {
            reject(error);
          })
          .catch((error) => {
            reject(error);
          });
      }
    );
  });
}

export function CreateElection(electionID, number, millisecondsWhenOpened) {
  const newElection = {
    electionID,
    number,
    millisecondsWhenOpened,
    open: true,
    candidates: [],
  };

  return new Promise((resolve, reject) => {
    set(ref(database, `elections/${electionID}`), newElection)
      .then(() => {
        resolve('Success');
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function CloseElection(election, millisecondsWhenClosed) {
  return new Promise((resolve, reject) => {
    set(ref(database, `elections/${election.electionID}`), {
      ...election,
      open: false,
      millisecondsWhenClosed,
    })
      .then(() => {
        resolve('Success');
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetElectionCandidates(electionID) {
  return new Promise((resolve, reject) => {
    get(ref(database, `elections/${electionID}/candidates`))
      .then((candidatesSnapshot) => {
        resolve(
          candidatesSnapshot.val()
            ? Object.values(candidatesSnapshot.val())
            : []
        );
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateElectionCandidates(electionID, citizen, candidateQuote) {
  return new Promise((resolve, reject) => {
    GetElectionCandidates(electionID).then((candidates) => {
      candidates.push({
        ...citizen,
        votes: { number: 0, voters: [] },
        quote: candidateQuote,
      });
      set(ref(database, `elections/${electionID}/candidates`), candidates)
        .then(() => resolve('Success'))
        .catch((error) => reject(error));
    });
  });
}

export function AddVote(electionID, candidateCitizenID, voterCitizenID) {
  return new Promise((resolve, reject) => {
    GetElectionCandidates(electionID)
      .then((candidates) => {
        const candidate = candidates.find((candidate) => {
          return candidate.citizenID === candidateCitizenID;
        });
        candidate.votes = {
          number: (candidate.votes.number += 1),
          voters: [...(candidate.votes.voters || []), voterCitizenID],
        };
        set(ref(database, `elections/${electionID}/candidates`), candidates)
          .then(() => {
            get(ref(database, `elections/${electionID}/voters`))
              .then((votersSnapshot) => {
                const voters = votersSnapshot.val() || [];
                voters.push(voterCitizenID);
                set(ref(database, `elections/${electionID}/voters`), voters)
                  .then(() => {
                    resolve('Success');
                  })
                  .catch((error) => reject(error));
              })
              .catch((error) => reject(error));
          })
          .catch((error) => reject(error));
      })
      .catch((error) => reject(error));
  });
}
