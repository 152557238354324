import { useState } from 'react';
import Controller from '../Controllers/AdminController';
import Button from '../Library/Button';
import Input from '../Library/Input';
import { CYRB53 } from '../Authentication';

const AdminView = ({ citizen }) => {
  const [nameInputComponentValue, setNameInputComponentValue] = useState('');
  const [voterPINInputComponentValue, setVoterPINInputComponentValue] =
    useState('');

  const controller = new Controller();

  function onChangeNameInputComponentValue(nameInput) {
    setNameInputComponentValue(nameInput);
  }

  function onChangeVoterPINInputComponentValue(voterPINInput) {
    setVoterPINInputComponentValue(voterPINInput);
  }

  function onClickAddCitizen() {
    const voterPINInputComponentValueH = CYRB53(
      voterPINInputComponentValue,
      process.env.REACT_APP_CYRB53_KEY
    );
    if (citizen.citizenID === process.env.REACT_APP_ADMIN_ID)
      controller.AddCitizen(
        nameInputComponentValue,
        voterPINInputComponentValueH
      );
  }
  return citizen.citizenID === process.env.REACT_APP_ADMIN_ID ? (
    <div className='bg-slate-400 w-full'>
      <div className='grid h-screen place-items-center'>
        <div className='p-16 bg-gray-100 border border-gray-400 rounded-xl shadow-sm'>
          <p className='text-xl pb-4 text-center font-bold'>Add Citizen</p>
          <p className='pt-4 text-center text-lg font-semibold'>Name</p>
          <Input
            className='flex justify-center'
            onChangeCallback={onChangeNameInputComponentValue}
          />
          <p className='pt-4 text-center text-lg font-semibold'>Voter PIN</p>
          <Input
            className='flex justify-center'
            onChangeCallback={onChangeVoterPINInputComponentValue}
          />
          <Button
            className='pt-6 flex justify-center'
            text='Add'
            onClick={onClickAddCitizen}
            enabled={
              nameInputComponentValue !== '' &&
              voterPINInputComponentValue !== ''
            }
          />
        </div>
      </div>
    </div>
  ) : null;
};

export default AdminView;
