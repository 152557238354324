import * as Model from '../Models/AdminModel';

class Controller {
  generateID() {
    let id = '';
    for (let i = 0; i <= 7; i++) {
      id += String(Math.floor(Math.random() * 10));
    }
    return id;
  }
  AddCitizen(name, voterPIN) {
    Model.AddVoterPIN(voterPIN);

    const citizenID = this.generateID();

    const newCitizen = {
      citizenID,
      name,
    };

    Model.AddCitizen(newCitizen, voterPIN);
  }
}

export default Controller;
