import 'firebase/database';
import { ref, set, get } from 'firebase/database';
import { GetDatabase } from '../DatabaseConfiguration';

const database = GetDatabase();

export function GetVoterPINs() {
  return new Promise((resolve, reject) => {
    get(ref(database, 'voterPINs'))
      .then((voterPINsSnapshot) => {
        resolve(voterPINsSnapshot.val());
      })
      .catch(() => {
        reject('Failed to retrieve voter PINs');
      });
  });
}

export function GetVoterPINsToCitizens() {
  return new Promise((resolve, reject) => {
    get(ref(database, 'voterPINsToCitizens'))
      .then((voterPINsToCitizensSnapshot) => {
        setTimeout(() => resolve(voterPINsToCitizensSnapshot.val()), 250);
      })
      .catch((error) => {
        reject('Failed to retrieve voterPINsToCitizenIDs');
      });
  });
}
