function Button({ text, onClick, enabled, link, className }) {
  function conditionalStyle(
    constant,
    condition,
    styleIfConditionTrue,
    styleIfConditionFalse
  ) {
    return `${constant} ${
      condition === true ? styleIfConditionTrue : styleIfConditionFalse
    }`;
  }

  return (
    <div className={className}>
      <button
        className={conditionalStyle(
          'text-white shadow rounded-full font-semibold',
          enabled !== undefined ? enabled : true,
          'bg-sky-500 hover:bg-sky-600',
          'bg-sky-200'
        )}
        onClick={enabled === true || enabled === undefined ? onClick : null}
      >
        {link !== undefined ? (
          <a href={link}>
            <p className='py-2 px-6'>{text}</p>
          </a>
        ) : (
          <p className='py-2 px-6'>{text}</p>
        )}
      </button>
    </div>
  );
}

export default Button;
