import { getAuth } from 'firebase/auth';
import { useState } from 'react';
import ContentView from './Views/ContentView';
import SignInView from './Views/SignInView';
import HeaderView from './Views/HeaderView';

function App() {
  const [isSignedIn, updateIsSignedIn] = useState(
    localStorage.getItem('isSignedIn') === 'true'
  );
  const [citizen, updateCitizen] = useState(
    localStorage.getItem('citizen') !== 'undefined' &&
      localStorage.getItem !== undefined
      ? JSON.parse(localStorage.getItem('citizen'))
      : {}
  );

  getAuth();

  return (
    <div className='flex min-h-screen'>
      {isSignedIn === false ? (
        <SignInView
          updateIsSignedIn={updateIsSignedIn}
          updateCitizen={updateCitizen}
        />
      ) : (
        <div className='w-full'>
          <HeaderView updateIsSignedIn={updateIsSignedIn} citizen={citizen} />
          <ContentView citizen={citizen} />
        </div>
      )}
    </div>
  );
}

console.log('App version 1.5');

export default App;
