import * as Model from '../Models/HomeModel';

class Controller {
  constructor(updateControllerStatus, setCurrentPresident, setElections) {
    this.setCurrentPresident = setCurrentPresident;
    this.setElections = setElections;
    this.updateControllerStatus = updateControllerStatus;
  }

  generateID() {
    let id = '';
    for (let i = 0; i <= 7; i++) {
      id += String(Math.floor(Math.random() * 10));
    }
    return id;
  }

  UpdateCurrentPresident() {
    Model.GetCurrentPresident()
      .then((currentPresident) => {
        this.setCurrentPresident(currentPresident);
      })
      .catch((error) => {
        this.updateControllerStatus('Error');
        console.log(error);
      });
  }

  UpdateElections() {
    this.updateControllerStatus('Loading');
    Model.GetElections()
      .then((elections) => {
        const sortedElections = elections.sort((election1, election2) => {
          return election2.number - election1.number;
        });
        this.setElections(sortedElections);
      })
      .catch((error) => {
        this.updateControllerStatus('Error');
        console.log(error);
      });
  }

  OpenNewElection(numberOfExistingElections) {
    Model.CreateElection(
      this.generateID(),
      numberOfExistingElections + 1,
      new Date().getTime()
    )
      .then(() => {
        this.UpdateElections();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  CloseElection(election) {
    Model.CloseElection(election, new Date().getTime()).then(() => {
      let highestVoteCount = 0;
      let possibleWinners = [];
      if (election.candidates) {
        possibleWinners = Object.values(election.candidates);
        Object.values(election.candidates).forEach((candidate) => {
          if (candidate.votes.number > highestVoteCount)
            highestVoteCount = candidate.votes.number;
        });
      }
      if (highestVoteCount > 0) {
        possibleWinners = possibleWinners.filter((possibleWinner) => {
          return possibleWinner.votes.number === highestVoteCount;
        });
        const winner =
          possibleWinners[Math.floor(Math.random() * possibleWinners.length)];
        Model.UpdatePresident(winner).then(() => {
          this.UpdateCurrentPresident();
          Model.UpdateElectionWinner(election, winner).then(() => {
            this.UpdateElections();
          });
        });
      } else {
        this.UpdateElections();
      }
    });
  }

  RunForPresident(electionID, citizen, candidateQuote) {
    Model.UpdateElectionCandidates(electionID, citizen, candidateQuote).then(
      () => {
        this.UpdateElections();
      }
    );
  }

  VoteForCandidate(electionID, candidateCitizenID, voterCitizenID) {
    Model.AddVote(electionID, candidateCitizenID, voterCitizenID).then(() => {
      this.UpdateElections();
    });
  }
}

export default Controller;
