import React, { useEffect, useState } from 'react';
import Button from '../Library/Button';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import Controller from '../Controllers/HomeController';
import Input from '../Library/Input';

const HomeView = React.memo(({ citizen }) => {
  const [currentPresident, setCurrentPresident] = useState({ name: '...' });
  const [controllerStatus, setControllerStatus] = useState('Loading');
  const [isEditingCandidateQuote, setIsEditingCandidateQuote] = useState(false);
  const [candidateQuote, setCandidateQuote] = useState('');
  const [elections, setElections] = useState([]);

  useEffect(() => {
    updateElections();
    updateCurrentPresident();
  }, []);

  const controller = new Controller(
    setControllerStatus,
    setCurrentPresident,
    setElections
  );

  function onClickOpenNewElection() {
    if (citizen.citizenID === process.env.REACT_APP_ADMIN_ID)
      controller.OpenNewElection(elections.length);
  }

  function updateElections() {
    controller.UpdateElections();
  }

  function updateCurrentPresident() {
    controller.UpdateCurrentPresident();
  }

  function onClickCloseElection(election) {
    if (citizen.citizenID === process.env.REACT_APP_ADMIN_ID)
      controller.CloseElection(election);
  }

  function onClickRunForPresident() {
    setIsEditingCandidateQuote(true);
  }

  function onClickRunForPresidentWithQuote(electionID) {
    controller.RunForPresident(electionID, citizen, candidateQuote);
    setIsEditingCandidateQuote(false);
  }

  function onClickCandidate(election, candidate) {
    if (Object.values(election.candidates).length > 2) {
      const sure = window.confirm(
        `Are you sure you want to vote for ${candidate.name}?`
      );
      if (sure) {
        controller.VoteForCandidate(
          election.electionID,
          candidate.citizenID,
          citizen.citizenID
        );
      }
    }
  }

  function isCitizenCandidate(candidates) {
    return candidates
      ? candidates.find((candidate) => {
          return candidate.citizenID === citizen.citizenID;
        }) !== undefined
      : false;
  }

  function isCitizenPresident() {
    return currentPresident.citizenID
      ? currentPresident.citizenID === citizen.citizenID
      : false;
  }

  function didCitizenVote(election) {
    return election.voters
      ? Object.values(election.voters).includes(citizen.citizenID)
      : false;
  }

  function onUpdateCandidateQuote(event) {
    console.log('onupdate', event);
    setCandidateQuote(event);
  }

  function didCitizenVoteForCandidate(election, candidateCitizenID) {
    const candidate = Object.values(election.candidates).find((candidate) => {
      return candidate.citizenID === candidateCitizenID;
    });
    return candidate.votes && candidate.votes.voters
      ? Object.values(candidate.votes.voters).includes(citizen.citizenID)
      : false;
  }

  function twoOrFewerCandidates(candidates) {
    return candidates ? Object.values(candidates).length < 3 : true;
  }

  function convertMillisecondsToReadableDateString(milliseconds) {
    const dateObject = new Date(milliseconds);
    return `${
      dateObject.getMonth() + 1
    }/${dateObject.getDate()}/${dateObject.getFullYear()}`;
  }

  function conditionalStyle(
    constant,
    condition,
    styleIfConditionTrue,
    styleIfConditionFalse
  ) {
    return `${constant} ${
      condition === true ? styleIfConditionTrue : styleIfConditionFalse
    }`;
  }

  return (
    <div className='w-full min-h-full bg-slate-300'>
      <div className='justify-center flex'>
        <div className=' bg-gray-100 rounded-3xl px-16 py-4 mt-2 mx-4 border border-gray-300 shadow'>
          <p className='text-center text-xl font-semibold'>
            CURRENT SHMOPE PRESIDENT
          </p>
          <p className='pb-2 pt-1 px-4 text-center text-3xl font-semibold bg-white border border-gray-300 shadow'>
            {currentPresident.name}
          </p>
          <Button
            text={
              isCitizenPresident() ? 'Update Current Law' : 'See Current Law'
            }
            className='flex justify-center pt-2'
            link='/law'
          />
        </div>
      </div>
      <div className='my-4 rounded bg-white shadow mx-4'>
        <div className='p-4'>
          {citizen.citizenID === process.env.REACT_APP_ADMIN_ID ? (
            <Button
              text='Open New Election'
              onClick={onClickOpenNewElection}
              className='flex justify-center'
            />
          ) : null}
          {elections.map((election) => {
            return (
              <div className='p-2'>
                <div className='bg-gray-100 p-2 border border-gray-400'>
                  <div className='flex justify-between'>
                    <div className='p-2'>
                      <p className='font-semibold text-xl align-middle'>
                        Election {election.number}
                      </p>
                      <p>
                        Opened{' '}
                        {new Date(
                          election.millisecondsWhenOpened
                        ).toLocaleDateString()}
                      </p>
                    </div>
                    <div className='flex flex-col'>
                      <p
                        className={conditionalStyle(
                          'rounded-xl font-bold text-lg px-4 py-2 text-center',
                          election.open === true,
                          'bg-black text-white',
                          'bg-white text-black border border-gray-400'
                        )}
                      >
                        {election.open === true
                          ? 'Open'
                          : `Closed ${convertMillisecondsToReadableDateString(
                              election.millisecondsWhenClosed
                            )}`}
                      </p>
                      {election.open === true &&
                      citizen.citizenID === process.env.REACT_APP_ADMIN_ID ? (
                        <Button
                          text='Close Election'
                          className='pt-2 flex'
                          onClick={() => onClickCloseElection(election)}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className='border-x border-b border-gray-400'>
                  <div className='p-2 flex justify-center'>
                    <div className='p-2'>
                      {election.open === true &&
                      !isCitizenCandidate(election.candidates) &&
                      twoOrFewerCandidates(election.candidates) ? (
                        <div>
                          {isEditingCandidateQuote ? (
                            <div className='pb-4'>
                              <p className='font-semibold text-center'>
                                Why should the citizens of Shmope vote for you?
                              </p>
                              <div className='flex justify-center pb-2'>
                                <Input
                                  onChangeCallback={onUpdateCandidateQuote}
                                  placeholderText='Optional'
                                />
                              </div>
                              <div className='flex gap-2 justify-center'>
                                <Button
                                  text='Cancel'
                                  className='flex justify-center'
                                  onClick={() =>
                                    setIsEditingCandidateQuote(false)
                                  }
                                />
                                <Button
                                  text='Run'
                                  className='flex justify-center'
                                  onClick={() =>
                                    onClickRunForPresidentWithQuote(
                                      election.electionID
                                    )
                                  }
                                />
                              </div>
                            </div>
                          ) : (
                            <Button
                              text='Run for President'
                              className='flex justify-center'
                              onClick={() => onClickRunForPresident()}
                            />
                          )}
                        </div>
                      ) : null}
                      {election.candidates &&
                      Object.values(election.candidates).length < 3 &&
                      election.open === true ? (
                        <p className='font-semibold text-lg bg-red-400 shadow py-2 px-4 rounded-lg text-white text-center mt-2'>
                          Need {3 - Object.values(election.candidates).length}{' '}
                          More{' '}
                          {3 - Object.values(election.candidates).length === 1
                            ? 'Candidate'
                            : 'Candidates'}{' '}
                          to Vote
                        </p>
                      ) : null}
                      {election.candidates?.map((candidate) => {
                        return (
                          <div>
                            {election.open === false ||
                            didCitizenVote(election) === true ? (
                              <div
                                className={conditionalStyle(
                                  'text-center font-semibold text-xl my-2 py-2 px-4 rounded-2xl shadow',
                                  election.winner?.citizenID ===
                                    candidate.citizenID,
                                  'bg-green-600 text-white',
                                  conditionalStyle(
                                    'border border-gray-400',
                                    didCitizenVoteForCandidate(
                                      election,
                                      candidate.citizenID
                                    ),
                                    'bg-green-200',
                                    'bg-white'
                                  )
                                )}
                              >
                                <div className='flex justify-center'>
                                  <div>
                                    <div className='flex justify-center gap-2'>
                                      {election.winner?.citizenID ===
                                      candidate.citizenID ? (
                                        <CheckCircleIcon className='h-7 w-7' />
                                      ) : null}
                                      <p className='text-center font-bold'>
                                        {candidate.name}
                                      </p>
                                    </div>
                                    {candidate.quote ? (
                                      <p className='font-normal'>
                                        "{candidate.quote}"
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                <p>
                                  {candidate.votes.number}{' '}
                                  {candidate.votes.number === 1
                                    ? 'vote'
                                    : 'votes'}
                                </p>
                              </div>
                            ) : (
                              <div
                                className={conditionalStyle(
                                  'hover:bg-gray-100 text-center font-semibold text-xl my-2 rounded-2xl shadow',
                                  election.winner?.citizenID ===
                                    candidate.citizenID,
                                  'bg-green-600 text-white',
                                  'border border-gray-400'
                                )}
                              >
                                <button
                                  onClick={() => {
                                    onClickCandidate(election, candidate);
                                  }}
                                  className='w-full py-2 px-4'
                                >
                                  <div className='flex justify-center'>
                                    <div>
                                      <div className='flex justify-center gap-2'>
                                        {election.winner?.citizenID ===
                                        candidate.citizenID ? (
                                          <CheckCircleIcon className='h-7 w-7' />
                                        ) : null}
                                        <p className='text-center font-bold'>
                                          {candidate.name}
                                        </p>
                                      </div>
                                      {candidate.quote ? (
                                        <p className='font-normal'>
                                          "{candidate.quote}"
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                  <p>
                                    {candidate.votes.number}{' '}
                                    {candidate.votes.number === 1
                                      ? 'vote'
                                      : 'votes'}
                                  </p>
                                </button>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});

export default HomeView;
