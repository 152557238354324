import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AdminView from './AdminView';
import HomeView from './HomeView';
import LawView from './LawView';

function Content({ citizen }) {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<HomeView citizen={citizen} />} />
        <Route path='/admin' element={<AdminView citizen={citizen} />} />
        <Route path='/law' element={<LawView citizen={citizen} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Content;
