import React, { useEffect, useState } from 'react';
import Controller from '../Controllers/LawController';
import Button from '../Library/Button';

const LawView = React.memo(({ citizen }) => {
  const [isCitizenPresident, setIsCitizenPresident] = useState();
  const [law, setLaw] = useState();
  const [editedLaw, setEditedLaw] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const controller = new Controller(citizen, setIsCitizenPresident, setLaw);

  useEffect(() => {
    controller.UpdateIsCitizenPresident();
    controller.UpdateLaw();
  }, []);

  function onClickSave() {
    controller.ChangeLaw(editedLaw, isCitizenPresident);
    setIsEditing(false);
  }

  function onClickUpdateLaw() {
    if (isCitizenPresident === true) {
      if (law && law.law) setEditedLaw(law.law);
      setIsEditing(true);
    }
  }

  function onClickCancel() {
    setIsEditing(false);
  }

  function onChangeLawInputComponentValue(input) {
    setEditedLaw(input.target.value);
  }

  return (
    <div className='bg-slate-200 w-full min-h-full'>
      <p className='text-center py-4 text-xl font-bold border-b border-gray-500'>
        Current Law
        {law !== undefined ? (
          <p className='font-semibold text-base px-4'>
            Last updated by President {law.updatedBy.name}{' '}
            {new Date(law.millisecondsWhenUpdated).toLocaleString()}
          </p>
        ) : null}
      </p>
      {isCitizenPresident !== undefined && law !== undefined ? (
        <div>
          {isCitizenPresident === true && isEditing ? (
            <div className='p-8'>
              <textarea
                value={editedLaw || ''}
                onChange={onChangeLawInputComponentValue}
                className='w-full h-96 p-4 border-2 border-gray-700 focus:border-black focus:outline-none focus:ring-1 focus:ring-black'
              ></textarea>
              <div className='pt-8 flex gap-6 justify-center'>
                <Button text='Cancel' onClick={onClickCancel} />
                <Button text='Save' onClick={onClickSave} />
              </div>
            </div>
          ) : (
            <div>
              <div className='flex p-4'>
                <textarea
                  value={law && law.law ? law.law : ''}
                  readOnly
                  onClick={() => {
                    if (isCitizenPresident) onClickUpdateLaw();
                  }}
                  contentEditable='false'
                  className='bg-white font-semibold focus:outline-none p-4 w-full h-96 resize-none border border-gray-400 shadow'
                ></textarea>
              </div>
              {isCitizenPresident === true ? (
                <Button
                  text='Update Law'
                  onClick={onClickUpdateLaw}
                  className='flex justify-center pt-4'
                />
              ) : null}
            </div>
          )}
        </div>
      ) : (
        <div className='flex justify-center animate-pulse text-6xl'>...</div>
      )}
    </div>
  );
});

export default LawView;
