import * as Model from '../Models/SignInModel';
import 'firebase/auth';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  setPersistence,
  signInAnonymously,
} from 'firebase/auth';

class Controller {
  constructor(updateControllerStatus, updateError) {
    Model.GetVoterPINs()
      .then((voterPINs) => {
        this.voterPINs = voterPINs;
        updateControllerStatus('Loaded');
      })
      .catch((error) => {
        updateControllerStatus('Error');
        updateError(error);
      });
  }

  SignIn(
    updateIsSignedIn,
    voterPINInputComponentValue,
    updateCitizen,
    updateControllerStatus,
    updateError
  ) {
    if (
      this.voterPINs &&
      Object.values(this.voterPINs).includes(voterPINInputComponentValue)
    ) {
      const auth = getAuth();
      return signInAnonymously(auth)
        .then(() => {
          Model.GetVoterPINsToCitizens()
            .then((voterPINsToCitizens) => {
              updateCitizen(voterPINsToCitizens[voterPINInputComponentValue]);
              localStorage.setItem(
                'citizen',
                JSON.stringify(voterPINsToCitizens[voterPINInputComponentValue])
              );
              updateIsSignedIn(true);
              localStorage.setItem('isSignedIn', 'true');
            })
            .catch((error) => {
              console.log(error);
              updateControllerStatus('Error');
              updateError(error);
            });
        })
        .catch((error) => {
          console.log(error);
          updateControllerStatus('Error');
          updateError(error);
        });
    }
  }
}

export default Controller;
