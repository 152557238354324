import * as Model from '../Models/LawModel';

class Controller {
  constructor(citizen, setIsCitizenPresident, setLaw) {
    this.citizen = citizen;
    this.setIsCitizenPresident = setIsCitizenPresident;
    this.setLaw = setLaw;
  }

  UpdateIsCitizenPresident() {
    Model.GetCurrentPresident()
      .then((currentPresident) => {
        this.setIsCitizenPresident(
          this.citizen.citizenID === currentPresident.citizenID
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  UpdateLaw() {
    Model.GetCurrentLaw()
      .then((currentLaw) => {
        this.setLaw(currentLaw);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  ChangeLaw(law, isCitizenPresident) {
    if (isCitizenPresident) {
      Model.ChangeLaw(law, this.citizen, new Date().getTime())
        .then(() => {
          this.UpdateLaw();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}

export default Controller;
