import Button from '../Library/Button';
import { ArchiveBoxArrowDownIcon } from '@heroicons/react/24/solid';
import Controller from '../Controllers/HeaderController';

function HeaderView({ updateIsSignedIn, citizen }) {
  const controller = new Controller();

  function onClickSignOut() {
    controller.SignOut(updateIsSignedIn);
  }

  return (
    <div className='top-0 sticky'>
      <div className='flex justify-between px-4 py-2 bg-white shadow'>
        <div className='flex flex-col justify-center text-xl font-semibold'>
          <a href='/' className='flex gap-1 border-y-2 border-black'>
            <div className='flex flex-col justify-center'>
              <ArchiveBoxArrowDownIcon className='h-5 w-5 border border-black rounded' />
            </div>
            Shmope Vote
          </a>
        </div>
        <div className='flex gap-2'>
          {citizen.citizenID === process.env.REACT_APP_ADMIN_ID ? (
            <Button text='Admin' link='/admin' />
          ) : null}
          <Button
            text='Sign Out'
            className='flex justify-end'
            onClick={onClickSignOut}
          />
        </div>
      </div>
    </div>
  );
}

export default HeaderView;
