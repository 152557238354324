import { getAuth, signOut } from 'firebase/auth';

class Controller {
  SignOut(updateIsSignedIn) {
    const auth = getAuth();
    signOut(auth).then(() => {
      window.location.assign('/');
      updateIsSignedIn(false);
      localStorage.clear();
    });
  }
}

export default Controller;
