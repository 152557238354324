import { get, ref, set } from 'firebase/database';
import { GetDatabase } from '../DatabaseConfiguration';

const database = GetDatabase();

export function GetCurrentPresident() {
  return new Promise((resolve, reject) => {
    get(ref(database, 'currentPresident'))
      .then((currentPresidentSnapshot) => {
        resolve(currentPresidentSnapshot.val());
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function GetCurrentLaw() {
  return new Promise((resolve, reject) => {
    get(ref(database, 'currentLaw'))
      .then((currentLawSnapshot) => {
        resolve(currentLawSnapshot.val() || {});
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}

export function ChangeLaw(law, president, millisecondsWhenUpdated) {
  return new Promise((resolve, reject) => {
    set(ref(database, 'currentLaw'), {
      law,
      updatedBy: president,
      millisecondsWhenUpdated,
    })
      .then(() => {
        resolve('Success');
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
}
