import { get, ref, set } from 'firebase/database';
import { GetDatabase } from '../DatabaseConfiguration';

const database = GetDatabase();

export function AddVoterPIN(voterPIN) {
  return new Promise((resolve, reject) => {
    get(ref(database, `voterPINs`)).then((voterPINsSnapshot) => {
      const voterPINs = Object.values(voterPINsSnapshot.val());
      voterPINs.push(voterPIN);
      set(ref(database, `voterPINs`), voterPINs)
        .then(() => {
          resolve('Success');
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  });
}

export function AddCitizen(citizen, voterPIN) {
  return new Promise((resolve, reject) => {
    set(ref(database, `voterPINsToCitizens/${voterPIN}`), citizen)
      .then(() => {
        resolve('Success');
      })
      .catch((error) => {
        reject(error);
        console.log(error);
      });
  });
}
