import Button from '../Library/Button';
import Input from '../Library/Input';
import Controller from '../Controllers/SignInController';
import React, { useState } from 'react';
import { ArchiveBoxArrowDownIcon } from '@heroicons/react/24/solid';
import { CYRB53 } from '../Authentication';

const SignIn = React.memo(({ updateIsSignedIn, updateCitizen }) => {
  const [voterPINInputComponentValue, updateVoterPINInputComponentValue] =
    useState('');
  const [controllerStatus, updateControllerStatus] = useState('Loading');
  const [error, updateError] = useState('');

  const controller = new Controller(updateControllerStatus, updateError);

  function onClickSignIn() {
    const voterPINInputComponentValueH = CYRB53(
      voterPINInputComponentValue,
      process.env.REACT_APP_CYRB53_KEY
    );
    controller.SignIn(
      updateIsSignedIn,
      voterPINInputComponentValueH,
      updateCitizen,
      updateControllerStatus,
      updateError
    );
  }

  function onEditVoterPINInputComponent(voterPINInput) {
    updateVoterPINInputComponentValue(voterPINInput);
  }
  return (
    <div className='bg-slate-400 w-full'>
      <div className='grid h-screen place-items-center'>
        <div className='p-16 mx-4 bg-gray-100 border border-gray-400 rounded-xl shadow-sm'>
          <div className='flex justify-center pb-4'>
            <div className='flex flex-col justify-center text-xl font-semibold'>
              <div className='flex gap-1 border-y-2 border-black'>
                <div className='flex flex-col justify-center'>
                  <ArchiveBoxArrowDownIcon className='h-5 w-5 border border-black rounded' />
                </div>
                Shmope Vote
              </div>
            </div>
          </div>
          <p className='p-4 text-xl font-semibold text-center bg-white rounded-2xl shadow-sm border border-gray-300'>
            You want to vote or run for the next Shmope President?
          </p>
          <p className='pt-4 text-center text-lg font-semibold'>
            Enter your secret voter PIN
          </p>
          <Input
            className='flex justify-center'
            onChangeCallback={onEditVoterPINInputComponent}
            onEnterCallback={onClickSignIn}
            type='password'
          />
          <Button
            className='pt-2 flex justify-center'
            text='Continue'
            onClick={onClickSignIn}
            enabled={
              controllerStatus === 'Loaded' &&
              voterPINInputComponentValue !== ''
            }
          />
        </div>
      </div>
    </div>
  );
});

export default SignIn;
